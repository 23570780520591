<template>
  <div class="accountWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="userInfoWrap mb20" :style="{ backgroundImage: 'url(' + accountBg + ')' }">
          <div class="userInfoBar">
            <div class="title mb20"><img :src="title" alt="账户中心"></div>
            <div class="userInfo">
              <div class="item"><span class="title">游戏账号:</span><span class="cons">{{ userInfo.username }}</span></div>
              <div class="item">
                <span class="title">登录密码:</span>
                <span class="cons">{{ userInfo.pwdIntro }}</span>
                <span v-if="web" class="modify" @click="resetPsw">修改</span>
              </div>
              <div class="item">
                <span class="title">绑定手机:</span>
                <span class="cons">{{ userInfo.mobile | formatMobile }}</span>
                <span v-if="web" class="modify" @click="resetMobile(userInfo.mobile)">修改</span>
              </div>
              <div class="item"><span class="title">真实姓名:</span><span class="cons">{{ userInfo.verifyInfo.realName ?
                userInfo.verifyInfo.realName : '未实名' }}</span><span v-if="!userInfo.verifyInfo.realName"
                  class="modify" @click="realName">去实名</span></div>
              <div class="item"><span class="title">身份证号:</span><span class="cons">{{ userInfo.verifyInfo.idcard ?
                userInfo.verifyInfo.idcard : '未实名' }}</span></div>
              <div class="item"><span class="title">大区选择:</span><span class="cons">{{ userInfo.gameArea }}</span></div>
              <div class="item"><span class="title">游戏昵称:</span><span class="cons">{{ userInfo.usernick }}</span><span
                  class="modify" @click="resetNickName(userInfo.mobile)">修改</span></div>
              <!-- <div class="item"><span class="title">游戏昵称:</span><span class="cons">{{userInfo.usernick}}</span></div> -->
            </div>
          </div>
        </div>
        <userInfoWX></userInfoWX>
        <div class="downCons bgWhite">
          <downItemWX :type="2"></downItemWX>
        </div>
      </div>
    </div>
    <pubMask v-if="isShowPask" :isShowPask="isShowPask" :propShowSwitch="showSwitch" :title="maskTitle"
      :yuanMobileProp="yuanMobile"></pubMask>
  </div>
</template>
<script>
import menuWX from '@/components/Menu'
import pubMask from '@/components/pubMask'
import userInfoWX from '@/components/userInfo'

import downItemWX from '@/views/downLoad/item'
import eventBus from '@/common/bus.js'
import { publicUrl } from '@/common/publicUrl.js'
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: 'account',
  components: {
    menuWX,
    pubMask,
    downItemWX,
    userInfoWX
  },
  data() {
    return {
      isLogin: true, //区分登录状态展示排行
      isRank: true, //区分子级是否需要展示排行
      userInfo: {
        verifyInfo: {},
        vipInfo: {},
        wallteInfo: {},
      },            // 用户信息
      isShowPask: false,          // 是否显示遮罩层
      showSwitch: 0,
      maskTitle: '',              // 弹窗的title
      yuanMobile: '',             // 修改手机号弹窗带着原手机号

      accountBg: require('@/assets/imgs/account/bg.png'),
      title: require('@/assets/imgs/login/title.png'),
      downDatas: [],
      ifweb:['9i173','17173']
    }
  },
  created() {
    eventBus.$on('closeMask', this.closeMask)
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goRecord() {       // 进入充值记录
      this.$router.push('/record?type=1')
    },
    getMemberInfo() {
      axios({
        method: 'post',
        url: '/user/getMemberInfo',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          userId: Cookies.get('wx_userId'),      // userId
          platForm: 'web',
        },
      }).then((res) => {
        if (res.data.state == 200) {
          this.userInfo = res.data.data;
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    },          // 获取用户信息
    resetPsw() {
      // console.log(this.userInfo.source, '用户信息');
      this.maskTitle = '修改密码'
      this.isShowPask = true;
      this.showSwitch = 3
    },               // 修改手机号
    closeMask() {
      this.isShowPask = false
    },
    resetMobile(mobile) {        // 修改手机号
      this.maskTitle = '修改手机号'
      this.yuanMobile = mobile;
      this.isShowPask = true;
      this.showSwitch = 2;
    },
    resetNickName(mobile) {        // 修改游戏昵称
      this.maskTitle = '修改游戏昵称'
      this.yuanMobile = mobile;
      this.isShowPask = true;
      this.showSwitch = 7
      // this.$alert('这是一段内容', '标题名称', {
      //     confirmButtonText: '确定',

      //   });
    },
    realName() {        // 去实名
      this.maskTitle = '实名'
      this.isShowPask = true;
      this.showSwitch = 8

    }
  },
  computed:{
    web(){
      let a = true
      for (let index = 0; index < this.ifweb.length; index++) {
        if(this.ifweb[index] == this.userInfo.source){ 
          a = false;    
          break
        }
        // console.log(this.ifweb[index],this.userInfo.source); 
      }
      return a;
    }
  },
  filters: {
    formatMobile(mobile) {
      if (mobile) {
        return mobile.substr(0, 3) + '****' + mobile.substr(7)
      }
    }
  }
}
</script>
<style lang="less">
.accountWrap {
  .divideWrap {
    .el-form-item {
      margin-bottom: 5px;
    }

    .el-form-item__label {
      font-size: 18px;
      font-weight: 700;
      line-height: 36px;
    }

    .el-form-item__content {
      line-height: 36px;
    }

    .el-input__inner {
      border-radius: 0;
      border-color: #333333;
      height: 36px;
      line-height: 36px;
    }

    .codeWrap {
      .el-input__inner {
        width: 132px;
      }
    }

    .submitWrap {
      .btn {
        padding: 0;
        width: 132px;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .bubbleWrap {
    .bubbleInfo {
      .el-radio__inner {
        width: 20px;
        height: 20px;

        &::after {
          width: 8px;
          height: 8px;
        }
      }

      .el-radio__label {
        font-size: 20px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.accountWrap {
  .userInfoWrap {
    padding: 40px 40px 50px 40px;
    height: 560px;
    box-sizing: border-box;
    background-repeat: no-repeat;

    .title {
      text-align: center;
    }

    .userInfo {
      padding-left: 220px;

      .item {
        margin-bottom: 12px;

        span {
          display: inline-block;
        }

        .title {
          width: 100px;
          font-size: 18px;
          font-weight: 600;
        }

        .cons {
          padding: 10px 5px;
          width: 210px;
          border-bottom: 1px dashed #333333;
          box-sizing: border-box;
        }

        .modify {
          margin-left: 20px;
          width: 50px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          color: #ffffff;
          border-radius: 4px;
          cursor: pointer;
          background: #D22190;
        }
      }
    }
  }

  .userAccountWrap {
    padding: 30px 30px 20px;
    box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255, 255, 255, 0.25);

    .tagWrap {
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid #333333;

      .tag {
        margin-right: 100px;
        padding: 8px 0;
        font-size: 20px;
        cursor: pointer;
        border-bottom: 4px solid #ffffff;
      }

      .active {
        font-size: 26px;
        border-bottom: 4px solid #D22190;
      }
    }

    .walletWrap {
      display: flex;
      justify-content: space-between;

      .balanceWrap,
      .bindBalanceWrap {
        padding-top: 10px;
      }

      .balance,
      .bindBalance {
        padding: 30px 15px;
        background-repeat: no-repeat;
        height: 128px;
        box-sizing: border-box;
        color: #ffffff;

        .tit {
          margin-bottom: 20px;
          font-size: 16px;
        }

        .num {
          display: block;
          margin-top: 20px;
          font-size: 30px;
        }
      }

      .balance {
        position: relative;
        z-index: 1;
        padding-right: 30px;
        width: 236px;
        min-width: 236px;

        .recharge {
          position: absolute;
          right: 1px;
          bottom: 6px;
          z-index: 2;
          display: inline-block;
          width: 30px;
          height: 90px;
          text-align: center;
          cursor: pointer;
        }
      }

      .bindBalance {
        width: 212px;
        min-width: 212px;
      }

      .divide {
        margin-right: 50px;
        width: 320px;
        min-width: 320px;

        .tip {
          color: #FF0000;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
        }

        .codeWrap {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .sendCode {
            display: inline-block;
            width: 77px;
            min-width: 77px;
            height: 36px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            background: #333333;
          }
        }
      }
    }

    .vipWrap {
      padding-top: 10px;

      .userInfo {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;

        .item {
          display: flex;
          align-items: center;

          .txt {
            span {
              display: inline-block;
              font-size: 18px;
            }
          }

          .num {
            margin-left: 5px;
            padding: 5px 0;
            width: 220px;
            min-width: 220px;
            border-bottom: 1px dashed #333333;
          }
        }
      }

      .btnWrap {
        margin-bottom: 20px;

        img {
          margin-right: 40px;
        }
      }

      .integralWrap {
        overflow: hidden;
        margin-right: -30px;

        .item {
          margin-right: 30px;
          margin-bottom: 30px;
          float: left;
          width: 150px;
          text-align: center;

          span {
            display: block;
            font-size: 20px;
          }

          .grade {
            height: 80px;
            line-height: 80px;
            font-weight: 700;
            background: rgba(210, 33, 144, 0.1);
            border-radius: 4px 4px 4px 4px;
          }

          .fen {
            height: 38px;
            line-height: 38px;
          }

          .btn {
            cursor: pointer;
          }
        }
      }

      .ruleWrap {
        .tit {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .cons {
          padding: 30px;
          min-height: 200px;
          line-height: 20px;
          background: rgba(210, 33, 144, 0.1);
          border-radius: 10px 10px 10px 10px;

          p {
            margin-bottom: 10px;
          }

          .txt1 {
            display: flex;

            span {
              font-size: 15px;
            }

            .num {
              min-width: 20px;
            }
          }

          .txt2 {
            font-size: 15px;
            padding-left: 20px;
          }

          .txt3 {
            padding-left: 30px;
          }
        }
      }
    }

    .bubbleWrap {
      padding-top: 10px;

      .userInfo {
        padding-bottom: 30px;
        overflow: hidden;

        .item {
          margin-bottom: 10px;
          float: left;
          width: 50%;

          span {
            display: inline-block;
          }

          .tit {
            margin-right: 5px;
            width: 130px;
            text-align: right;
            font-size: 18px;
          }

          .txt {
            padding: 5px 0;
            width: 210px;
            font-size: 18px;
            border-bottom: 1px dashed #333333;
          }

          .exchange {
            margin-left: 5px;
            width: 90px;
            height: 30px;
            line-height: 30px;
            color: #ffffff;
            text-align: center;
            background: #D22190;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }

      .bubbleInfo {
        .tit {
          height: 100px;
          line-height: 100px;
          font-size: 20px;
          background: rgba(210, 33, 144, 0.1);
          border-radius: 10px 10px 10px 10px;
          text-align: center;
        }

        .cons {
          border-left: 1px solid #D22190;
          border-top: 1px solid #D22190;
          overflow: hidden;

          .item {
            float: left;
            padding-left: 60px;
            width: 50%;
            height: 100px;
            line-height: 100px;
            border-right: 1px solid #D22190;
            border-bottom: 1px solid #D22190;
            box-sizing: border-box;
          }
        }
      }

      .confirmWrap {
        text-align: center;

        span {
          display: inline-block;
          width: 150px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          color: #ffffff;
          background: #D22190;
          border-radius: 4px;
        }
      }
    }
  }

  .downCons {
    box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255, 255, 255, 0.25);
    padding-top: 50px;
  }
}
</style>
